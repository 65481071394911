import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

type InitSentryOpts = {
  app: App,
  router: Router,
}

const sentryEnvOptions = {
  default: {
    dsn: 'https://1b0ab78b628716498b78c428114c06b5@sentry.stg.obibiz.com/3'
  },
  production: {
    dsn: 'https://370f63004ca2379cc09e180510d4b03e@sentry-collect.hashkey.com/2'
  }
};

// Sentry 初始化方法
export const initSentry = ({ app, router }: InitSentryOpts) => {
  const { dsn } = sentryEnvOptions[import.meta.env.MODE as 'default' | 'production'] || sentryEnvOptions.default;

  Sentry.init({
    app,
    dsn,
    integrations: [
      // 性能插件，暂时不开启
      // Sentry.browserTracingIntegration({ router }),
      // 会话重播插件
      Sentry.replayIntegration()
    ],
    // 请求全链路追踪，接口需要网关和后端支持，目前暂不开启
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // 事务采样率。设置为 100%
    tracesSampleRate: 1.0,
    // 重播会话采样率（进入到页面就开始录制并持续整个用户会话过程）。设置为 0%
    replaysSessionSampleRate: 0,
    // 重播错误采样率（发生错误时才开始录制，将记录错误发生之前最多一分钟的事件）。设置为 100%
    replaysOnErrorSampleRate: 1.0
  });
};

type AxiosError = {
  config: {
    url: string
  },
  request: XMLHttpRequest
}

// Sentry 接口网关异常上报
export const sentryGatewayExceptionUpload = (error: AxiosError) => {
  // 需要监听服务异常的接口列表
  const pathList = [
    '/api/basic/config_v2'
  ];

  /**
   * HttpStatus = 0 的情况：
   * 1. url路径或协议头错误
   * 2. 网络断开
   * 3. 防火墙拦截了请求
   * 4. 跨域请求
   * 5. 服务端响应前取消请求
   */
  if (pathList.some(path => error?.config?.url?.includes(path)) && error?.request?.status === 0) {
    Sentry.captureException(new Error(`TYPE:GatewayExceptionWEB URL: ${error?.config?.url}`));
  }
};