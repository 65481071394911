import { useConfigStore } from '@/store/config';
/**
 * @file 通用校验及正则
 */

// 正则表达式
export const regExp = {
  email: /@/,
  // email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
  // email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  phone: {
    'zh-cn': /^1[3456789]\d{9}$/,
    'en-us': /^[0-9]{5,13}$/,
    other: /^[0-9]{5,13}$/
  },
  number: /^[0-9]*$/
};

export const vaildator = {
  /**
     * isEmail
     * @param {String} email 邮箱
     * @return {Boolean} true/false
     */
  isEmail: (email: string) => {
    return regExp.email.test(email);
  },
  /**
     * isMobilePhone
     * @param {String} mobile 手机号
     * @param {String} locale 默认en-us
     * @return {Boolean} true/false
     */
  isMobilePhone: (phone: string, locale = 'en-us') => {
    if (locale in regExp.phone) {
      const phoneRegExp: any = regExp.phone;
      return phoneRegExp[locale].test(phone);
    }
    return false;
  },
  /**
     * 校验密码
     * @param {String} value 密码
     * @return {Boolean} true/false
     */
  validPassword: (value: string) => {
    return !(value.length < 8 ||
            value.length > 50 ||
            !/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value));
  },
  /**
     * 校验IP
     * @param {String} value IP
     * @return {Boolean} true/false
     */
  isIp: (str: string) => {
    if (str === '') {
      return false;
    }
    const ipv4Patten = /^((25[0-5]|2[0-4]\d|1?\d?\d)\.){3}(25[0-5]|2[0-4]\d|1?\d?\d)$/;
    const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)$/;
    // return /^((25[0-5]|2[0-4]\d|1?\d?\d)\.){3}(25[0-5]|2[0-4]\d|1?\d?\d)$/.test(
    //   str
    // );
    return ipv4Patten.test(str) || ipv6Pattern.test(str);
  },

  /**
   * 校验 config_v2 配置接口中不允许用于注册的邮箱
   * @param {String} value 邮箱 email
   * @return {Boolean} 限制的返回true   /false
  */
  mailboxNotAllowed: (str: string) => {
    const configStore = useConfigStore();
    let allowed = false;
    if (str && configStore.config && configStore.config.excludeEmailSuffix) {
      const regList = configStore.config.excludeEmailSuffix.split(',');
      for (let i = 0; i < regList.length; i++) {
        const reg = regList[i];
        if (reg && new RegExp(reg + '$').test(str)) {
          allowed = true;
        }
      }
    }
    return allowed;
  }
};

