<template>
  <!-- z-index 要设置到最大层级最高 2147483647-->
  <transition name="moveR" >
    <div v-if="visible" class="verify">
      <span class="alert"></span>
      <div>
        <div class="gray-900 text-sm-m mb16">
          {{ $t("您有充值待地址认证") }}
        </div>
        <div class="btn_group">
          <span class="gray-700 text-sm-m pointer" @click="closeVerifyModal">{{ $t("稍后处理") }}</span>
          <span class="primary-500 text-sm-m ml12 pointer" @click="toPage"> 
            {{ $t("立即认证") }}
          </span>
          <span class="right_arrow pointer" @click="toPage"></span>
        </div>
      </div>
      <span class="closed" @click="closeVerifyModal"></span>
    </div>
  </transition>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { $addressVerifyApi } from '@/config/api';

const visible = ref(false);
const router = useRouter();
let timeInter: any = '';

onMounted(() => {
  // 已关闭弹框 无需展示弹框
  if (localStorage.getItem('address_verify_visible') == 'false') {
    visible.value = false;
    return;
  } else {
    // 第一次获取数据后 10s轮循
    getAddressVerify();
    timeInter = setInterval(() => {
      getAddressVerify();
    }, 10000);
  }
});
onBeforeUnmount(() => {
  clearInterval(timeInter);
});

// 关闭右上角待地址认证弹框
const closeVerifyModal = () => {
  visible.value = false;
  clearInterval(timeInter);
  localStorage.setItem('address_verify_visible', 'false');
};
// 获取待地址认证数据总数
const getAddressVerify = async() => {
  let res: any = await $addressVerifyApi.getAddressVerifyList();
  // 一旦有数据返回 打开弹框
  if (res?.code == '0' && res?.data > 0) {
    visible.value = true;
    // 添加标记 代表弹框是否出现过
    localStorage.setItem('already_verify_modal', 'true');
  } else {
    // 如果already_verify_modal 为 true  结束轮循、关闭弹窗(处理从有数据到0的情况)
    if (localStorage.getItem('already_verify_modal') == 'true') {
      closeVerifyModal();
    } else {
      // 关闭弹窗 处理从0到1的情况
      visible.value = false;
    }
  }
};
const toPage = () => {
  closeVerifyModal();
  router.push({ path: '/custody/assetView/savingAccount/record?active=recharge' });
};
</script>

<style lang="scss" scoped>
.verify {
  display: flex;
  justify-content: flex-start;
  width: 400px;
  padding: 16px;
  position: fixed;
  top: 80px;
  right: 24px;
  background: var(--base-white);
  border-radius: 12px;
  z-index: 2147483647;
  border: 1px solid var(--gray-100);
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.btn_group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.ml12 {
  margin-left: 12px;
}

.mb16 {
  margin-bottom: 16px
}

.right_arrow {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  background: url(@/assets/img/hk-svg/arrow-right.svg) center/20px 20px no-repeat;
}

.closed {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 20px;
  height: 20px;
  background: url(@/assets/img/user/icon-closed.svg) 0 0/100% 100% no-repeat;
}

.alert {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  display: inline-block;
  background: url(@/assets/img/icon/icon-warn.svg) 0 0/20px 20px no-repeat;
  background-size: cover;
}

.pointer {
  cursor: pointer;
}

.moveR-enter-active,  .moveR-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}
.moveR-enter-from,  .moveR-leave {
  transform: translateX(100%);
}
.moveR-leave-to {
  transform: translateX(100%);
}

</style>