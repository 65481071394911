import { $api, assetApi, $assets } from '@/config/api';

import { isClient } from '@vueuse/shared';
import { useUserStore } from './User';
import CONST from '@/config/const';

export const useCommonStore = defineStore('commonStore', {
  state: () => ({
    isMobile: <any>false,
    lang: isClient ? (localStorage.getItem('lang') || 'en-US') : 'en-US',
    isDark: isClient ? (localStorage.getItem('isDark') || 'dark') : 'dark',
    currencys: <Array<Record<string, any>>>[], // 币列表
    // currencyMap: <any>{}, // 币map
    symbols: <Array<any>>[], // 交易对列表
    // symbolMap: {}, // 交易对map
    config: null, // 币，币对，币选项
    // 汇率时间控制
    rateTime: 0,
    // 汇率
    rates: <Array<Record<string, any>>>[],
    suffix: '', // 当前的折合汇率
    prefix: '', // 当前折合币种符号
    // 热门交易对
    hotSymbols: <Record<string, any>>{},
    isWithdrawal: false,
    isLoading: false
  }),
  getters: {
    $currencys(state) {
      return state.currencys;
    },
    $symbols(state) {
      return state.symbols;
    },
    getSuffix(state): string {
      return state.suffix;
    },
    /**
     * 币种对应的法币汇率
     *
     * 数据格式： {tokenId: rates<legal, value>}
     * @returns object
     */
    ratesMap(state) {
      const { rates } = state;

      const rateMap: Record<string, object> = {};
      rates.forEach((item: Record<string, any>) => {
        rateMap[item.token] = item.rates;
      });

      return rateMap;
    },
    symbolMap(state) {
      const obj: any = {};

      if (!state.symbols || !state.symbols.length) {
        return obj;
      }

      state.symbols.forEach((item: any) => {
        obj[item.symbolId] = item;
      });

      return obj;
    },
    currencyMap(state) {
      const obj: any = {};

      if (!state.currencys || !state.currencys.length) {
        return obj;
      }

      state.currencys.forEach((item: any) => {
        obj[item.tokenId] = item;
      });

      return obj;
    }
  },
  actions: {
    // 币，币对，币选项
    getConfig() {
      if (this.config) {
        return;
      }

      $api.getConfig().then((data: any) => {
        if (data.success) {
          this.config = data.data;
        }
      });
    },

    // 获取所有币种
    async getCurrency() {
      // if (this.currencys && this.currencys.length) {
      //   return;
      // }
      const res: any = await $api.getCurrency();
      if (res.success && res.data) {
        /**
         * 用户权限 > token权限
         * @param userPermissions 用户所有权限 对象类型
         * @param tokenPermissions 当前token权限 boolean
         * @param tokenType token类型
         * @param actionType deposit 或 withdraw
         * @param isEffect 是否生效
         * @returns boolean
         */
        const getWithdrawDepositPermissions = (tokenType: string, actionType: string, userPermissions: any, tokenPermissions: boolean, isEffect: boolean) => {

          let user = tokenPermissions;
          const token: boolean = tokenPermissions;

          if (actionType === 'deposit') {
            if (tokenType == 'REAL_MONEY') {
              user = userPermissions.allowFiatDeposit;
            } else if (tokenType == 'SECURITY_TOKEN') {
              user = userPermissions.allowSTTokenDeposit;
            } else {
              user = userPermissions.allowTokenDeposit;
            }
          } else if (actionType === 'withdraw') {
            if (tokenType == 'REAL_MONEY') {
              user = userPermissions.allowFiatWithdraw;
            } else if (tokenType == 'SECURITY_TOKEN') {
              user = userPermissions.allowSTTokenWithdraw;
            } else {
              user = userPermissions.allowTokenWithdraw;
            }
          }

          // 用户权限(user) > 币种权限(token)
          if (user) {
            if (isEffect) {
              return token;
            } else {
              return false;
            }
          } else {
            return user;
          }
        };

        /**
         * 用户权限 > token权限
         * @param userPermissions 用户权限
         * @param tokenPermissions token权限
         * @param isEffect token权限是否生效
         * @returns boolean
         */
        const getTransferPermissions = (userPermissions: boolean, tokenPermissions: boolean, isEffect: boolean) => {
          if (userPermissions) {
            if (isEffect) {
              return tokenPermissions;
            } else {
              return false;
            }
          } else {
            return userPermissions;
          }
        };
        const userStore = useUserStore();
        const getPermissions = (data: any) => {

          const userInfo = userStore.userinfo;
          const { riskSettings } = userInfo;

          let { userType } = userInfo;

          if (userStore.isAuthorizedAdmin) {
            userType = userInfo.masterUserType;
          }

          const isTrader = userType === CONST.USER_TYPE.AUTHORIZED_TRADER;

          // 用户权限
          const userPermissions = {
            allowFiatDeposit: riskSettings ? !riskSettings?.disallowFiatDeposit : true,
            allowFiatWithdraw: riskSettings ? !riskSettings?.disallowFiatWithdraw : true,
            allowSTTokenDeposit: riskSettings ? !riskSettings?.disallowSTTokenDeposit : true,
            allowSTTokenWithdraw: riskSettings ? !riskSettings?.disallowSTTokenWithdraw : true,
            allowTokenDeposit: riskSettings ? !riskSettings?.disallowTokenDeposit : true,
            allowTokenWithdraw: riskSettings ? !riskSettings?.disallowTokenWithdraw : true,
            allowTransferCustodyToTrade: isTrader ? false : (riskSettings ? !riskSettings?.disallowTransferCustodyToTrade : defaultTransferPermission),
            allowTransferFiatToTrade: isTrader ? false : (riskSettings ? !riskSettings?.disallowTransferFiatToTrade : defaultTransferPermission),
            allowTransferTradeToCustody: isTrader ? false : (riskSettings ? !riskSettings?.disallowTransferTradeToCustody : defaultTransferPermission),
            allowTransferTradeToFiat: isTrader ? false : (riskSettings ? !riskSettings?.disallowTransferTradeToFiat : defaultTransferPermission),
            allowTransferTradeToTrade: isTrader ? false : (riskSettings ? !riskSettings?.disallowTransferTradeToTrade : defaultTransferPermission),
            allowOptTransferOut: riskSettings ? !riskSettings?.disallowOptTransferOut : true
          };

          return data?.map((x: any) => {
            const { riskSettings, allowDeposit, allowWithdraw } = x;
            const { supportUserTypes } = riskSettings;
            const isEffect = supportUserTypes ? supportUserTypes?.find((x: any) => x == userType) : true;

            // 当前token的权限
            const tokenPermissions = {
              allowTransferCustodyToTrade: !riskSettings?.disallowTransferCustodyToTrade,
              allowTransferFiatToTrade: !riskSettings?.disallowTransferFiatToTrade,
              allowTransferTradeToCustody: !riskSettings?.disallowTransferTradeToCustody,
              allowTransferTradeToFiat: !riskSettings?.disallowTransferTradeToFiat
            };
            return {
              ...x,
              final: { // 9026:PI降级零售特殊处理，只保留用户类型作用于充值，提现、划转忽略用户类型
                deposit: getWithdrawDepositPermissions(x.tokenType, 'deposit', userPermissions, allowDeposit, isEffect),
                withdraw: getWithdrawDepositPermissions(x.tokenType, 'withdraw', userPermissions, allowWithdraw, true), 
                transferCustodyToTrade: getTransferPermissions(userPermissions.allowTransferCustodyToTrade, tokenPermissions.allowTransferCustodyToTrade, true),
                transferFiatToTrade: getTransferPermissions(userPermissions.allowTransferFiatToTrade, tokenPermissions.allowTransferFiatToTrade, true),
                transferTradeToCustody: getTransferPermissions(userPermissions.allowTransferTradeToCustody, tokenPermissions.allowTransferTradeToCustody, true),
                transferTradeToFiat: getTransferPermissions(userPermissions.allowTransferTradeToFiat, tokenPermissions.allowTransferTradeToFiat, true),
                transferTradeToTrade: userPermissions.allowTransferTradeToTrade,
                optTransferOut: getTransferPermissions(userPermissions.allowOptTransferOut, true, isEffect),
                optTransferIn: true
              }
            };
          });
        };

        if (userStore.isLogin) {
          this.currencys = getPermissions(res.data);
        } else {
          this.currencys = res.data;
        }
        sessionStorage.setItem('currencyList', JSON.stringify(this.currencys));
        this.getRates();
      } else {
        // 处理错误情况,1秒后重新调用
        setTimeout(() => {
          this.getCurrency();
        }, 1000);
      }
    },

    // 所有币对列表
    // force 强制刷新
    async getSymbols(params = { force: false }) {
      if (this.symbols && this.symbols.length && !params.force) {
        return;
      }

      const data: any = await $api.getConfig();

      // 先粗糙处理防重复赋值 引起的交易对变化
      if (this.symbols && this.symbols.length && !params.force) {
        return;
      }

      if (data.success) {
        const symbols = data.data.symbol.filter((item: any) => {
          // 过滤不需要展示的交易对
          if (!item.showStatus) {
            return false;
          }
          // TODO 其他过滤条件

          return true;
        })
          .map((item: any) => ({
            ...item,
            isWhite: (data.data.publicWhiteSymbols || []).includes(item.symbolId)
          }))
          .sort((a: any, b: any) => b.symbolWeight - a.symbolWeight);

        this.symbols = symbols;
      } else {
        // 处理错误情况,1秒后重新调用
        setTimeout(() => {
          this.getSymbols();
        }, 1000);
      }
    },

    // 获取汇率
    getRates() {
      assetApi
        .getRates({})
        .then((data: any) => {
          if (data.success) {
            this.rates = data.data;
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          clearTimeout(this.rateTime);
          this.rateTime = setTimeout(() => {
            this.getRates();
          }, 5000);
        });
    },

    // 清除汇率timeout
    clearRatesTimes() {
      clearTimeout(this.rateTime);
    },

    async getHotSymbol() {
      if (this.hotSymbols.length) {
        return;
      }

      const res: any = await $api.getHotSymbol({ number: 5 });

      if (res.success) {
        this.hotSymbols = res.data;
      }
    },
    async getWithdrawal() {
      const res:any = await $assets.quota_info({  token_id: 'BTC', chain_type: 'BTC' });
      if (res.code === 200) {
        this.isWithdrawal = res.data.refuseReason;
      }
    },
    // 资产下架或者不可见时返回true
    isAssetInvisible(tokenId: string) {
      const res = this.currencys?.find(item => item?.tokenId === tokenId);

      return !res || (res?.hasOwnProperty('status') && res?.status !== 1);
    }
  }
});
